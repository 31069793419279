import { navigate } from "gatsby";

export const isBrowser = () => typeof window !== "undefined";

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {};

const setUser = (user) =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user));

export const handleLogin = (endpoint, { username, password }) => {
  if (username === `whypeopleUser` && password === `whypeoplePass`) {
    setUser({
      username: `whypeopleUser`,
      name: `whypeopleUser`,
    });

    return navigate("/clientportal");
  }
  throw new Error("Throwing error from API!");
  // return fetch(endpoint, buildMutationOptions("POST", data, options)).then(
  //   const isJson = response.headers
  //   .get("content-type")
  //   ?.includes("application/json");
  //   const data = isJson ? await response.json() : null;

  //   if (!response.ok) {
  //     return Promise.reject(data);
  //   }

  //   return data;
  // );
};

export const logout = (callback) => {
  setUser({});
  callback();
};

export const isLoggedIn = () => {
  const user = getUser();
  return !!user.username;
};
