import React from "react";
import { Router } from "@reach/router";
import Clientportal from "../privatePages/clientportal";
import PrivateRoute from "../components/PrivateRoute";
import NotFound from "./404";

const App = () => (
  <Router basepath="/">
    <PrivateRoute path="/clientportal" component={Clientportal} />
    <NotFound default />
  </Router>
);

export default App;
