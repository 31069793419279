import React from "react";
import Button from "./Button";

const ClientFormRow = ({ title, description, file }) => (
  <div className="w-full bg-white my-8 py-5 text-left">
    <h3 className="text-primary-darkest text-sm sm:text-xl lg:text-2xl 2xl:text-3xl font-semibold px-2 lg:px-6">
      {title}
    </h3>
    <div className="flex flex-col md:flex-row">
      <p className="text-primary-darkest mt-4 lg:mt-8 text-sm md:text-lg xl:text-xl 3xl:text-2xl p-2 lg:px-6">
        {description}
      </p>

      <Button
        size="sm"
        className="h-14  mx-4 mt-4  lg:mt-8 text-sm md:text-lg lg:text-xl xl:text-2xl 3xl:text-3xl"
      >
        <a href={file} download className="text-white hover:text-white">
          Descargar
        </a>
      </Button>
    </div>
  </div>
);

export default ClientFormRow;
