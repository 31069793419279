import React from "react";
import { StaticImage } from "gatsby-plugin-image";
// import { useReactToPrint } from "react-to-print";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout/Layout";
import ClientFormRow from "../components/ClientFormRow";

const Clientportal = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "xlsx" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <div className="absolute z-0 w-[100vw] max-w-[100%]">
        <div className="bgfilter" />
        <StaticImage
          src="../images/whypeople_imagen6.jpg"
          objectFit="cover"
          alt="whypeople"
          style={{
            width: "100vw",
            maxWidth: "100%",
            position: "fixed",
            zIndex: "-5",
            minHeight: "100vh",
          }}
        />
      </div>
      <div className="min-h-[calc(100vh-10.5rem)] py-4 relative z-5">
        <div className="max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-3xl 3xl:max-w-4xl mx-auto md:py-12">
          <div className="flex flex-row relative z-5">
            <div className="border-b-2 border-b-solid border-b-primary-default w-[10%]" />
            <h1 className="text-4xl xl:text-5xl 2xl:text-6xl 4xl:text-7xl 7xl:text-8xl font-bold leading-none text-center text-primary-darkest w-[80%]">
              Área Cliente
            </h1>
            <div className="border-b-2 border-b-solid border-b-primary-default w-[10%]" />
          </div>
        </div>

        <section className="w-5/6 md:w-4/6 4xl:max-w-7xl mx-auto my-12 py-12  bg-primary-lighter rounded-lg text-center px-4">
          <h2 className="text-primary-darkest text-lg sm:text-xl lg:text-2xl 2xl:text-3xl font-semibold">
            Bienvenid@ a tu area de cliente. Aquí podrás gestionar los recursos
            y herramientas que Whypeople te proporciona.
          </h2>
          <ClientFormRow
            title="Formulario de historial profesional"
            description="Aquí tienes el formulario de historial profesional en formato xlsx para descargar y rellenar en tu equipo."
            file={data.allFile.edges[0].node.publicURL}
          />
        </section>
      </div>
    </Layout>
  );
};

export default Clientportal;
